import React from 'react';
import { Box, Flex, Text, Button, Icon, useColorModeValue, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { BsCloudCheck } from 'react-icons/bs';
import { useProfile } from '../../../../context/profileProvider';
import ConfirmProfileSubmission from './ConfirmProfileSubmission';
import { getPublicProfileUrl } from '../../../../utils/pathsUtil';
import { useUnsavedChanges } from '../../../../context/UnsavedChangesProvider';

interface IProfileProps {
  borderColor?: string;
}

const StickyHeader = ({ borderColor }: IProfileProps) => {
  const profileData = useProfile();
  const { hasUnsavedChanges } = useUnsavedChanges();

  const handlePublicProfileClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!profileData.savedNickname) {
      e.preventDefault();
      profileData.showMessage('Please choose a unique nickname to access your public profile.', 'info');
    } else if (!profileData.savedPublicProfileVisibility) {
      e.preventDefault();
      profileData.showMessage('Please make your profile public in order to access it.', 'info');
    } else {
      window.open(getPublicProfileUrl() + '/' + profileData.savedNickname, '_blank');
    }
  };

  return (
    <>
      <Box
        display={{ base: 'flex' }}
        mx="auto"
        maxW={'1150px'}
        alignItems={{ base: 'flex-start', sm: 'center' }}
        flexDir={{ base: 'column-reverse', lg: 'row' }}
        top={0}
        _dark={{ bg: 'gray.800' }}
      >
        <Flex justifyContent="flex-end" width="full">
          <Button
            isDisabled={!(profileData.savedNickname && profileData.savedPublicProfileVisibility && profileData.publicProfileVisibility)}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
            mr={2}
            ml={2}
            size={'md'}
            height={'44px'}
            minWidth={'116px'}
            _disabled={{ color: '#b9bfc1', backgroundColor: '#e7e7e7' }}
            _hover={profileData.savedNickname && profileData.savedPublicProfileVisibility && profileData.publicProfileVisibility ? { borderColor: 'gray.400' } : {}}
            variant={'outline'}
            onClick={e => {
              if (!profileData.savedNickname) {
                e.preventDefault();
                profileData.showMessage('Please choose a unique nickname to access your public profile.', 'info');
              } else if (!profileData.savedPublicProfileVisibility) {
                e.preventDefault();
                profileData.showMessage('Please save your profile in order to access it.', 'info');
              } else {
                window.open(getPublicProfileUrl() + '/' + profileData.savedNickname, '_blank');
              }
            }}
          >
            <Box display="flex" alignItems="center">
              <svg
                className="icon"
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
              </svg>
            </Box>
            Preview
          </Button>

          <Button
            isDisabled={profileData.disableSaveProfileButton || !hasUnsavedChanges}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
            mr={2}
            ml={2}
            size={'md'}
            height={'44px'}
            minWidth={'116px'}
            _disabled={{ color: '#b9bfc1', backgroundColor: '#e7e7e7' }}
            _hover={!(profileData.disableSaveProfileButton || !hasUnsavedChanges) ? { borderColor: 'gray.400' } : {}}
            variant={'outline'}
            onClick={profileData.handleSaveClick}
            isLoading={profileData.isProfileSaving}
          >
            <BsCloudCheck /> Save
          </Button>

          <ConfirmProfileSubmission
            isOpen={profileData.confirmProfileSubmissionModalOpen}
            onClose={() => profileData.setConfirmProfileSubmissionModalOpen(false)}
            onContinue={profileData.handleProfileSubmit}
            dailyQuataTotal={profileData.dailyQuataTotal}
            dailyQuataRemain={profileData.dailyQuataRemain}
          />
        </Flex>
      </Box>
    </>
  );
};

export default StickyHeader;
