import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { InfoCircle } from 'react-bootstrap-icons';
import CropperOriginal from 'react-easy-crop';
import { BsCheck, BsX } from 'react-icons/bs';
import { useProfile } from '../../../../context/profileProvider';
import { getPublicProfileUrl } from '../../../../utils/pathsUtil';
import W3sAvatar from '../../../W3sAvatar/W3sAvatar';
import { IUserProfile } from '../../../userlib_new/utils/profile-interfaces';

interface IProfileProps {
  userProfile: IUserProfile;
  borderColor?: string;
}

const uniqueId = new Date().getTime();

const Cropper = CropperOriginal as any;
const ProfileHeader = ({ userProfile, borderColor }: IProfileProps) => {
  const profileData = useProfile();
  const nicknameChangeDebouncerRef = useRef<NodeJS.Timeout | null>(null);
  const [nickname, setNickname] = useState<string>('');

  useEffect(() => {
    setNickname(profileData.nickname);
  }, [profileData.nickname]);

  const triggerProfileImageInput = () => {
    const profileImageInput = document.getElementById('profileImageInput');
    if (profileImageInput) {
      profileImageInput.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      profileData.onFileSelected(event.target.files[0]);
    }
  };

  const triggerFileInput = () => {
    if (
      confirm(
        'Please note that uploading a new CV will overwrite existing profile details such as experience and education. Do you want to proceed?',
      )
    ) {
      const fileInput = document.getElementById('fileInput');
      if (fileInput) {
        fileInput.click();
      }
    }
  };

  return (
    <>
      <Box
        mx="auto"
        my={5}
        p={{ base: 4, md: 6 }}
        width={{ base: '99%', '2xl': 'full' }}
        maxW={'1150px'}
        borderWidth="1px"
        borderRadius="lg"
        borderColor={borderColor}
        boxShadow="lg"
        _dark={{ borderColor: 'white', bg: '#282A35' }}
        bg={'white'}
        position="relative"
      >
        <Box
          position="absolute"
          top={4}
          right={4}
          display="flex"
          alignItems="center"
          gap={2}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Text
            fontSize={{ base: 'md', lg: '16px' }}
            whiteSpace="nowrap"
            mb={0}
            fontStyle={{ base: 'italic', md: 'normal' }}
            color={{ base: 'gray.500', md: 'unset' }}
          >
            Profile Visibility
          </Text>
          <Box position="relative" display="flex" alignItems="center">
            <Switch
              id="switch"
              colorScheme="green"
              isChecked={profileData.publicProfileVisibility || false}
              onChange={e => profileData.handlePublicProfileVisibility(e.target.checked)}
              sx={{
                width: '95px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                '> span': {
                  width: '100%',
                  height: '100%',
                },
                '> span > span': {
                  width: '26px',
                  height: '26px',
                  margin: '2px',
                  border: '0.36px solid #0000000A',
                  boxShadow: '0px 2.16px 5.76px 0px #00000026',
                  transition: 'all 0.3s ease-in-out',
                  transform: profileData.publicProfileVisibility ? 'translateX(65px) !important' : 'translateX(0)',
                },
              }}
            />
            <Box
              position="absolute"
              top="50%"
              left={profileData.publicProfileVisibility ? '40%' : '65%'}
              transform="translate(-50%, -50%)"
              fontSize="sm"
              fontWeight="600"
              color={profileData.publicProfileVisibility ? 'white' : 'gray.600'}
              pointerEvents="none"
            >
              {profileData.publicProfileVisibility ? 'Public' : 'Private'}
            </Box>
          </Box>
        </Box>

        <VStack spacing={{ base: 2, md: 4 }} align="stretch">
          <Flex direction={{ base: 'column', md: 'row' }} align="center" gap={4}>
            <Box position="relative" borderRadius="full" boxSize="100px" overflow="hidden" className="group">
              <W3sAvatar
                borderRadius="full"
                boxSize="100px"
                src={
                  profileData.userProfile?.personal?.profilePicture
                    ? `${getPublicProfileUrl()}/${profileData.userProfile?.personal?.profilePicture}?key=${uniqueId}`
                    : ''
                }
              />
              {profileData.isProfileImageUploading && (
                <Center position="absolute" top="0" right="0" bottom="0" left="0" bg="rgba(255, 255, 255, 0.6)">
                  <Spinner size="lg" />
                </Center>
              )}

              <Button
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                opacity={profileData.userProfile?.personal?.profilePicture ? 0 : 1}
                _groupHover={{ opacity: 1 }}
                onClick={triggerProfileImageInput}
                display={!profileData.userProfile?.personal?.profilePicture ? 'block' : ['none', 'none', 'block']}
                variant="outline"
                style={{
                  border: 'none',
                }}
                _hover={{
                  bg: 'transparent',
                  color: 'white',
                  border: 'none',
                }}
              >
                <Text fontSize="14">Upload</Text>
              </Button>

              <input
                type="file"
                id="profileImageInput"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={profileData.handleProfileImageChange}
              />
            </Box>

            <Modal isOpen={profileData.isModalOpen} onClose={() => profileData.setIsModalOpen(false)} size={['full', 'md', 'lg', 'xl']}>
              <ModalOverlay />
              <ModalContent maxW="90vw" maxH="80vh">
                <ModalHeader>Edit Image</ModalHeader>
                <ModalCloseButton />
                <ModalBody minH="300px" minW="200px" maxH="70vh" style={{ position: 'relative' }}>
                  {profileData.selectedImage && (
                    <Cropper
                      image={profileData.selectedImage}
                      crop={profileData.crop}
                      zoom={profileData.zoom}
                      aspect={1}
                      onCropChange={profileData.setCrop}
                      onZoomChange={profileData.setZoom}
                      onCropComplete={profileData.onCropComplete}
                      style={{ width: '100%', height: '100%' }}
                    />
                  )}
                </ModalBody>
                <ModalFooter>
                  <Flex justify="space-between" align="center" width="100%">
                    <Text textAlign="left" align="left" fontSize={12}>
                      Adjust the image by zooming in or out and dragging it to the desired position.
                    </Text>
                    <Button colorScheme="blue" onClick={profileData.handleEditConfirm}>
                      Confirm Edit
                    </Button>
                  </Flex>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Text fontSize="2xl" fontWeight="bold">
              {userProfile.personal?.firstName} {userProfile.personal?.lastName}
            </Text>
          </Flex>

          <Divider my={4} />

          <Box mb={-2}>
            <Text fontSize="md" fontWeight="400" color="gray.500" fontStyle="italic" _dark={{ color: 'gray.400' }}>
              <InfoCircle
                style={{
                  display: 'inline-block',
                  marginRight: '8px',
                  fontSize: '16px',
                  color: 'var(--chakra-colors-black-400)',
                }}
              />
              Create your unique profile URL by choosing a nickname. This will be your public profile address that you can share with
              others. Note: Your profile must be set to "Public" to be accessible by others.
            </Text>
          </Box>

          <HStack w="100%" alignItems="baseline" mt={0}>
            <FormControl isInvalid={profileData.isNicknameAvailable === false} display="flex" alignItems="center">
              <InputGroup flex="1" minWidth="0" flexDirection={{ base: 'column', md: 'row' }}>
                <Box
                  as="span"
                  px={3}
                  h="40px"
                  display="flex"
                  alignItems="center"
                  borderWidth={1}
                  borderRadius="md 0 0 md"
                  bg="gray.50"
                  color="gray.500"
                  fontSize="sm"
                  whiteSpace="nowrap"
                  userSelect="none"
                  _dark={{ bg: '#2D3748', color: 'gray.400', borderColor: 'gray.600' }}
                  borderColor="gray.200"
                >
                  {getPublicProfileUrl()}/
                </Box>
                <Tooltip
                  label="This nickname is already in use, please choose a different one"
                  bg="#ec6f6f"
                  color="white"
                  p="10px"
                  fontSize="14px"
                  isOpen={!!(nickname && profileData.isNicknameAvailable === false)}
                  hasArrow={true}
                >
                  <Input
                    id="contact"
                    placeholder="Public Profile Nickname"
                    bg="white"
                    pr="75px"
                    borderLeftRadius="0"
                    borderRightRadius={{ base: '0', md: 'md' }}
                    minWidth="200px"
                    ml={{ base: '0px', md: '-1px' }}
                    borderColor="gray.200"
                    borderWidth="1px"
                    _hover={{
                      borderColor: '#04AA6D',
                      bg: 'white',
                      borderWidth: '1px',
                    }}
                    _focus={{
                      borderColor: '#04AA6D',
                      boxShadow: 'none',
                      zIndex: 1,
                      bg: 'white',
                      borderWidth: '1px',
                    }}
                    _placeholder={{ color: 'gray.400' }}
                    value={nickname ?? ''}
                    onChange={e => {
                      const inputFieldValue = e.target.value.replace(/^\s+|\s+$/g, '');
                      setNickname(inputFieldValue);
                      clearTimeout(nicknameChangeDebouncerRef.current as NodeJS.Timeout);
                      nicknameChangeDebouncerRef.current = setTimeout(() => {
                        profileData.handleNicknameChange(inputFieldValue);
                      }, 500);
                    }}
                    isInvalid={profileData.isNicknameAvailable === false}
                    _dark={{
                      bg: '#3E4252',
                      borderColor: 'gray.600',
                      borderWidth: '1px',
                      _hover: {
                        borderColor: '#04AA6D',
                        bg: '#3E4252',
                        borderWidth: '1px',
                      },
                      _focus: {
                        borderColor: '#04AA6D',
                        boxShadow: 'none',
                        bg: '#3E4252',
                        borderWidth: '1px',
                      },
                      _placeholder: { color: 'gray.500' },
                    }}
                  />
                </Tooltip>

                {/* Validation Icon */}
                {profileData.isNicknameAvailable !== null && (
                  <InputRightElement top={{ base: '40px', md: '0px' }}>
                    <Icon
                      as={profileData.isNicknameAvailable ? BsCheck : BsX}
                      color={profileData.isNicknameAvailable ? 'green.500' : 'red.500'}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            </FormControl>
          </HStack>
        </VStack>
      </Box>
    </>
  );
};

export default ProfileHeader;
