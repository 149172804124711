import React, { useMemo } from 'react';
import {
  VStack,
  Text,
  Switch,
  useColorModeValue,
  HStack,
  Link,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ICertification } from '../../../userlib_new/utils/profile-interfaces';
import { AccordionSection } from './AccordionSection';
import { useProfile } from '../../../../context/profileProvider';
import { getMonth } from '../../../../utils/dateTimeUtil';
import W3CertifiedIcon from '../../../icons/W3CertifiedIcon/W3CertifiedIcon';

interface ICertificatesProps {
  certifications: ICertification[];
}

export const Certificates = ({ certifications }: ICertificatesProps) => {
  const profileData = useProfile();
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const iconSize = useBreakpointValue({ base: '22', md: '44' });
  const formattedDates = useMemo(() => {
    return certifications.map((cert) => {
      if (cert.dateCompleted) {
        const givenDate = new Date(cert.dateCompleted);
        return `${givenDate.getDate()} ${getMonth(givenDate.getMonth())} ${givenDate.getFullYear()}`;
      } else {
        return '-';
      }
    });
  }, [certifications]);

  return (
    <AccordionSection title="Certificates" sectionKey="certifications">
      <VStack spacing={4} width="full">
        {certifications.length > 0 ? (
          certifications.map((cert, index) => (
            <HStack
              key={index}
              justifyContent="space-between"
              flexDirection={{ base: 'column', md: 'row' }}
              align="center"
              p={4}
              borderWidth="1px"
              borderColor={borderColor}
              borderRadius="md"
              width="100%"
              _dark={{ bg: '#3E4252' }}
              gap={{ base: 4, md: 0 }}
            >
              <HStack flex="1">
                <W3CertifiedIcon size={iconSize} />
                <Text fontWeight="bold" mx={{ base: 0, md: 2 }} mb={0}>
                  {cert.title}
                </Text>
              </HStack>
              <Text flex="1" fontSize="sm" mx={2} mb={0}>
                {formattedDates[index]}
              </Text>
              <HStack gap={{ base: 0, md: 2 }}>
                {cert.verifyUrl && (
                  <Link href={cert.verifyUrl} color="blue.500" isExternal>
                    View
                  </Link>
                )}
                <Switch
                  id={`cert-switch-${index}`}
                  size="md"
                  colorScheme="green"
                  mx={2}
                  isChecked={cert?.visible && cert?.verifyUrl ? true : false}
                  onChange={(e) =>
                    profileData.handleCertificationChange(index, e.target.checked)
                  }
                  isDisabled={!cert.verifyUrl}
                />
              </HStack>
            </HStack>
          ))
        ) : (
          <Text alignSelf="flex-start">No certificates available.</Text>
        )}
      </VStack>
    </AccordionSection>
  );
};
