import React, { useCallback, useEffect } from 'react';
import { Accordion, Box, useColorModeValue } from '@chakra-ui/react';
import { useProfile } from '../../../context/profileProvider';
import ProfileHeader from './ProfileHeader/ProfileHeader';
import { Personalia } from './AccordionSection/Personalia';
import { ContactInfo } from './AccordionSection/ContactInfo';
import { Experience } from './AccordionSection/Experience';
import { Education } from './AccordionSection/Educations';
import { ActivityScore } from './AccordionSection/ActivityScores';
import { Certificates } from './AccordionSection/Certifications';
import { SpacesSection } from './AccordionSection/Spaces';
import { Skills } from './AccordionSection/Skills';
import { Interests } from './AccordionSection/Interests';
import PublicProfile from './ProfileHeader/PublicProfile';
import StickyHeader from './ProfileHeader/StickyHeader';
import { AboutMe } from './AccordionSection/AboutMe';
import { useUnsavedChanges } from '../../../context/UnsavedChangesProvider';

interface IProps {
  refElement: React.RefObject<HTMLDivElement>;
}

const UserProfile = ({ refElement }: IProps) => {
  const profileData = useProfile();
  const { hasUnsavedChanges } = useUnsavedChanges();
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const inputBgColor = useColorModeValue('#f9f9f9', 'gray.900');

  const handleBeforeUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        // Note: Modern browsers may not display this custom message
        return 'Are you sure you want to leave?';
      }
    },
    [hasUnsavedChanges],
  );

  useEffect(() => {
    if (hasUnsavedChanges) {
      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [hasUnsavedChanges, handleBeforeUnload]);

  return (
    <Box pb={{ base: 6, xl: 0 }} w='100%' ref={refElement}>
      <StickyHeader borderColor={borderColor} />

      <ProfileHeader userProfile={profileData.userProfile} borderColor={borderColor} />

      {/*<PublicProfile
        borderColor={borderColor}
        inputBgColor={inputBgColor}
      />*/}

      <Accordion
        allowMultiple
        mx="auto"
        my={5}
        width={{ base: '99%', '2xl': 'full' }}
        maxW={'1150px'}
        index={profileData.accordionIndex}
        onChange={profileData.setAccordionIndex}
      >
        {/* About Me Section */}
        <AboutMe userProfile={profileData.userProfile} inputBgColor={inputBgColor} />

        {/* Personalia Section */}
        <Personalia userProfile={profileData.userProfile} inputBgColor={inputBgColor} />

        {/* Contact Information Section */}
        <ContactInfo contactData={profileData.userProfile?.contacts ?? {}} inputBgColor={inputBgColor} />

        {/* Skills Section */}
        <Skills filteredSkills={profileData.mySkills} />

        {/* Experience Section */}
        <Experience experienceData={profileData.userProfile?.experience ?? []} />

        {/* Education Section */}
        <Education educationData={profileData.userProfile?.education ?? []} />

        {/* Activity Scores */}
        <ActivityScore activityScores={profileData.activityScore || []} />

        {/* certifications Section */}
        <Certificates certifications={profileData.certifications || []} />

        {/* Spaces Section */}
        <SpacesSection spaces={profileData.spaces || []} isLoadingSpaces={profileData.isLoadingSpaces} />

        {/* Interests Section */}
        <Interests interests={profileData.interests || []} />
      </Accordion>
    </Box>
  );
};

export default UserProfile;
