import React from 'react';
import {
  Box,
  VStack,
  HStack,
  Input,
  Button,
  FormControl,
  FormLabel,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { IExperience } from '../../../userlib_new/utils/profile-interfaces';
import { AccordionSection } from './AccordionSection';
import { useProfile } from '../../../../context/profileProvider';
import { BsPlusCircle } from 'react-icons/bs';
import 'react-datepicker/dist/react-datepicker.css';
import DatePickerComponent from '../../../ReactDatePicker/ReactDatePicker';

interface IExperienceProps {
  experienceData: IExperience[];
}

export const Experience = ({ experienceData }: IExperienceProps) => {
  const profileData = useProfile();
  return (
    <AccordionSection title="Experience" sectionKey="experience">
      <VStack spacing={4} align="stretch">
        {experienceData.length > 0 ? (
          experienceData.map((job, index) => (
            <Box key={index} p={5} borderWidth="1px" borderRadius="lg">
              <VStack spacing={3} align="stretch">
                <FormControl
                  isRequired
                  isInvalid={
                    profileData.invalidExperienceFields[index]?.position
                  }
                >
                  <FormLabel htmlFor={`edu-position-${index}`}>
                    Position
                  </FormLabel>
                  <Input
                    id={`edu-position-${index}`}
                    value={job.position ?? ''}
                    onChange={(e) =>
                      profileData.handleExperienceChange(
                        index,
                        'position',
                        e.target.value,
                      )
                    }
                    _dark={{ bg: '#3E4252' }}
                  />
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={
                    profileData.invalidExperienceFields[index]?.company
                  }
                >
                  <FormLabel htmlFor={`edu-company-${index}`}>
                    Company
                  </FormLabel>
                  <Input
                    id={`edu-company-${index}`}
                    value={job.company ?? ''}
                    onChange={(e) =>
                      profileData.handleExperienceChange(
                        index,
                        'company',
                        e.target.value,
                      )
                    }
                    _dark={{ bg: '#3E4252' }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor={`edu-location-${index}`}>
                    Location
                  </FormLabel>
                  <Input
                    id={`edu-location-${index}`}
                    value={job.location ?? ''}
                    onChange={(e) =>
                      profileData.handleExperienceChange(
                        index,
                        'location',
                        e.target.value,
                      )
                    }
                    _dark={{ bg: '#3E4252' }}
                  />
                </FormControl>
                <HStack
                  flexDirection={{ base: 'column', md: 'row' }}
                >
                  <FormControl>
                    <FormLabel htmlFor={`from-${index}`}>From</FormLabel>
                    <DatePickerComponent
                      id={`from-${index}`}
                      selected={job.startDate}
                      onChange={(date) => {
                        profileData.handleExperienceChange(
                          index,
                          'startDate',
                          date,
                        );
                      }}
                      dateFormat="MM-yyyy"
                      placeholderText="MM-yyyy"
                      showMonthYearPicker
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor={`to-${index}`}>To</FormLabel>
                    <DatePickerComponent
                      id={`to-${index}`}
                      selected={job.endDate}
                      onChange={(date) => {
                        profileData.handleExperienceChange(
                          index,
                          'endDate',
                          date,
                        );
                      }}
                      dateFormat="MM-yyyy"
                      placeholderText="MM-yyyy"
                      showMonthYearPicker
                    />
                  </FormControl>
                </HStack>
                <FormControl
                  isRequired
                  isInvalid={
                    profileData.invalidExperienceFields[index]?.dutiesString
                  }
                >
                  <FormLabel htmlFor={`duties-${index}`}>
                    Responsibilities
                  </FormLabel>
                  <Textarea
                    id={`duties-${index}`}
                    value={job?.dutiesString ?? ''}
                    onChange={(e) =>
                      profileData.handleExperienceChange(
                        index,
                        'dutiesString',
                        e.target.value,
                      )
                    }
                    _dark={{ bg: '#3E4252' }}
                  />
                  {/* {profileData.invalidExperienceFields[index]?.dutiesString && <FormErrorMessage>Responsibilities are required</FormErrorMessage>} */}
                </FormControl>
                <Button
                  variant="danger"
                  size="xs"
                  alignSelf="flex-start"
                  onClick={() => profileData.deleteExperienceEntry(index)}
                >
                  Delete
                </Button>
              </VStack>
            </Box>
          ))
        ) : (
          <Text>No experience available.</Text>
        )}
        <Button
          rightIcon={<BsPlusCircle />}
          onClick={profileData.addExperienceEntry}
          alignSelf="flex-start"
          variant="outline"
        >
          Add
        </Button>
      </VStack>
    </AccordionSection>
  );
};
