import React, { useMemo } from 'react';
import { useProfile } from '../../../../context/profileProvider';
import { ISpaces } from '../../../userlib_new/utils/profile-interfaces';
import {
  Box,
  Button,
  HStack,
  Icon,
  Image,
  Input,
  Link,
  Switch,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaRedo } from 'react-icons/fa';
import Loading from '../loading';
import { AccordionSection } from './AccordionSection';
import { getPublicProfileUrl } from '../../../../utils/pathsUtil';

interface ISpacesProps {
  spaces: ISpaces[];
  isLoadingSpaces: boolean;
}

export const SpacesSection = ({ spaces, isLoadingSpaces }: ISpacesProps) => {
  const profileData = useProfile();
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const hasSpaces = useMemo(() => spaces.length > 0, [spaces]);

  return (
    <AccordionSection title="Spaces" sectionKey="spaces">
      {isLoadingSpaces && <Loading />}
      {!isLoadingSpaces &&
        hasSpaces &&
        spaces.map((space, index) => (
          <HStack
            key={space.spaceId}
            spacing={4}
            align={{ base: 'center', md: 'start' }}
            borderWidth="1px"
            borderColor={borderColor}
            borderRadius="lg"
            boxShadow="md"
            p={5}
            my={5}
            _dark={{ bg: '#3E4252' }}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Box
              position="relative"
              boxSize="100px"
              overflow="hidden"
              className="group"
            >
              {profileData.spaceThumbnailPendingReqs.includes(space.spaceId) ? (
                <Loading />
              ) : (
                <>
                  <Image
                    borderRadius="md"
                    boxSize="100px"
                    objectFit="cover"
                    src={`${getPublicProfileUrl()}/${space.pictureUrl}?key=${new Date().getTime()}`}
                    alt=""
                  />
                  <Tooltip label="Refresh thumbnail" placement="top">
                    <Button
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      opacity={space.pictureUrl ? 0 : 1}
                      _groupHover={{ opacity: 1 }}
                      onClick={() =>
                        profileData.handleRefreshSpaceThumbnail(space.spaceId)
                      }
                      display={
                        !space.pictureUrl ? 'block' : ['none', 'none', 'block']
                      }
                      variant="outline"
                      style={{
                        border: 'none',
                      }}
                      _hover={{
                        bg: 'transparent',
                        color: 'white',
                        border: 'none',
                      }}
                    >
                      <Icon as={FaRedo} boxSize={5} />
                    </Button>
                  </Tooltip>
                </>
              )}
            </Box>
            <VStack align="start" justify="center" flex={1} width="full">
              <Text fontWeight="bold">URL</Text>
              <Link
                href={space.spaceUrl}
                isExternal
                fontSize="sm"
                color="blue.500"
                wordBreak="break-word"
              >
                {space.spaceUrl}
              </Link>
              <Text fontWeight="bold">Description</Text>
              <Input
                id={`description${index}`}
                value={space.description}
                onChange={(e) =>
                  profileData.handleSpaceChange(
                    index,
                    'description',
                    e.target.value,
                  )
                }
                width="full"
              />
            </VStack>
            <Switch
              size="md"
              id={`spaces-switch-${index}`}
              isChecked={space?.visible || false}
              colorScheme="green"
              onChange={(e) =>
                profileData.handleSpaceChange(
                  index,
                  'visible',
                  e.target.checked,
                )
              }
            />
          </HStack>
        ))}
      {!isLoadingSpaces && !hasSpaces && <Text>No spaces available.</Text>}
    </AccordionSection>
  );
};
